.rct-horizontal-lines {
    visibility: hidden;
}

.rct-items {
    pointer-events: none;
    top: 40px;
}


.rct-vertical-lines {
    color: #e4e2e2;

}


.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid;

}

.react-calendar-timeline {
    height: 100% !important;
}

.rct-outer {
    height: calc(100% - 55px) !important;
}

.rct-scroll {
    height: 100% !important;
    /* Internet Explorer 10+ */
    -ms-overflow-style: none !important;
    /* Firefox */
    scrollbar-width: none !important;
    width: 100% !important;
}

.rct-scroll::-webkit-scrollbar {
    /* Safari and Chrome */
    display: none !important;
}

.rct-scroll:hover {
    cursor: move !important;
}

.rct-vl {
    height: 100% !important;
}

.react-calendar-timeline .rct-dateHeader {
    border-left: none;
    border-bottom: none;
    background-color: white;
}

.react-calendar-timeline .rct-header-root {
    border-bottom: none;
    background-color: #f7f7f7;
}

.react-calendar-timeline .rct-calendar-header {
    border: none;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: none;
}