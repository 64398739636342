.underL {
    position: relative;
    transition: color 0.3s;

}

#hamburger-menu {
    position: fixed;
    overflow: clip scroll;
    max-height: 85dvh;
    top: 0;
}

#hamburger-menu::-webkit-scrollbar {
    width: 12px;
    /* Width of the scrollbar */
}

#hamburger-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#hamburger-menu::-webkit-scrollbar-thumb {
    background-color: #afacac;
    border-radius: 6px;
}

.underL::after {
    content: "";
    position: absolute;
    background-color: #605fbe;
    height: 3px;
    width: 0;
    left: 0;
    bottom: -5px;
    transition: 0.3s;
}

.shadow2 {
    transition: .5s ease;
}

.shadow2:hover {
    /*
    box-shadow:
        1px 1px #373737,
        2px 2px #373737,
        3px 3px #373737,
        4px 4px #373737,
        5px 5px #373737,
        6px 6px #373737;
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
    transition: .5s ease;*/

    transform: scale(1.1);

}


.custom-collapse .ant-collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-collapse .ant-collapse-content {
    padding-left: 0;
    /* Ajusta según sea necesario */
}

@keyframes move-confetti {

    0%,
    50% {
        transform: translateY(0vh);
    }

    100% {
        transform: translateY(100vh);
    }
}

.move-confetti {
    height: 100vh;
    width: 100vw;
    position: absolute;
    animation: move-confetti 10s ease-in-out;
    z-index: 1000;
}