.filepond--root {
    font-family: poppins;
}

.filepond--item-panel {

    background-color: white;
}

.filepond--panel-root {
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.filepond--item {}

.filepond--file {
    color: black;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

}

.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    border-radius: 6px;
  }
  
  .react-tagsinput--focused {
    border-color: #6366f1 !important;
    border: 2px solid #6366f1 !important;
  }
  
  .react-tagsinput-tag {
    background-color: #1677ff !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 4px !important;
    border: 0px !important;
    color: white !important;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }
  

  
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
  }

.filepond--credits {
    display: none;
}