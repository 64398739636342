.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: rgb(255, 68, 68);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: rgb(255, 68, 68) !important;
}

.flicking-arrow-disabled,
.flicking-arrow-disabled {
    cursor: default;
    display: none !important;
}