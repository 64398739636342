.cardDash:hover {
    background: linear-gradient(to right, #1d46ff, #6E66D6);
    cursor: pointer;
    scale: 1.02;

}

.cardDash {
    background: linear-gradient(to right, #a0afee, #9088fa);
}

.active-card {
    background: linear-gradient(to right, #1d46ff, #6E66D6);
    pointer-events: none;
}

.tremor-Tracker-trackingBlock {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(267 196 8/var(--tw-bg-opacity))
}


li>svg {
    margin-right: 10px;
}

* {
    box-sizing: border-box;
}