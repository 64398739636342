.bg-image {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(2, 2, 2, 0.4)), url(../../../assets/bg-uni.jpg);
	background-size: cover;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.google-btn {
	width: 184px;
	height: 42px;
	background-color: #4285f4;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
	cursor: pointer;
}

.google-btn .google-icon-wrapper {
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
	width: 40px;
	height: 40px;
	border-radius: 2px;
	background-color: #fff;
}

.google-btn .google-icon {

	margin-top: 11px;
	margin-left: 11px;
	width: 18px;
	height: 18px;
}

.google-btn .btn-text {
	float: right;
	margin: 11px 11px 0 0;
	color: #fff;
	font-size: 14px;
	letter-spacing: 0.2px;
	font-family: "Roboto";
}

.google-btn:hover {
	box-shadow: 0 0 6px #4285f4;
}

.google-btn:active {
	background: #1669f2;
}

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
	margin: 0px;
	padding: 0px;
}




.context {
	width: 100%;
	position: absolute;
	top: 50vh;

}

.context h1 {
	text-align: center;
	color: #fff;
	font-size: 50px;
}


body {
	background-color: #f2f2f2;
}

